import { useEffect } from 'react';
import { useUser } from './useUser';
import { useLocalStorage } from './useLocalStorage';
import useLoginApi from '../apis/authentication/hooks/useLoginApi';
import { useNavigate } from 'react-router-dom';

export const useAuth = (from) => {
    const { request: loginUser, error, loading } = useLoginApi();
    const { user, addUser, removeUser } = useUser();
    const { getItem } = useLocalStorage();
    const navigate = useNavigate();

    useEffect(() => {
        const user = getItem('@UPASSAEVENE_USER');
        if (user) {
            addUser(JSON.parse(user));
            navigate(from, { replace: true });
        }
    }, []);

    const login = (credentials) => {
        loginUser({
            username: credentials.email,
            password: credentials.password,
        }).then((response) => {
            if (response) {
                addUser(response.data);
                navigate(from, { replace: true });
            }
        });
    };

    const logout = () => {
        removeUser();
        navigate('/', { replace: true });
    };

    return { user, login, logout, error, loading };
};
