import React from 'react';

//Components
import Loader from '../loader/Loader';

function Submitting() {
    return (
        <div className='submitting'>
            <Loader />
        </div>
    );
}

export default Submitting;
