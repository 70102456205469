import React, { useState } from 'react';
import Btn from '../../../components/button/Btn';
import BorderIcon from '../../../components/icons/BorderIcon';
import WarningIcon from '../../../components/icons/WarningIcon';
import Notification from '../../../components/notification/Notification';

function NotificationError({ error }) {
    const [isNotificationOpen, setIsNotificationOpen] = useState(true);

    const closeNotification = () => {
        setIsNotificationOpen(false);
    };

    if (!isNotificationOpen) {
        return null;
    }

    return (
        <Notification status={'error'} bgOnClick={closeNotification}>
            <BorderIcon color='error'>
                <WarningIcon />
            </BorderIcon>
            <p>{error}</p>
            <Btn onClick={closeNotification}>Fermer</Btn>
        </Notification>
    );
}

export default NotificationError;
