import React, { useEffect, useState } from 'react';

const RideCompleted = ({ ride }) => {
    const [stateMessage, setStateMessage] = useState({});
    useEffect(() => {
        switch (ride?.state) {
            case 'Terminée':
                setStateMessage({ state: 'success', message: 'Course terminée' });
                break;
            case 'Déclinée':
                setStateMessage({ state: 'error', message: "Course annulée par l'utilisateur" });
                break;
            case 'Refusée':
                setStateMessage({ state: 'error', message: 'Course refusée par le chauffeur' });
                break;
            case 'Annulée':
                setStateMessage({ state: 'error', message: 'Course annulée par le chauffeur' });
                break;

            default:
                break;
        }
    }, [ride]);

    return (
        <div className='ride ride--is-finished'>
            <div className='ride__header'>
                <p className='ride__title'>
                    {ride.user.nom} {ride.user.prenom}
                </p>
                <p>{ride.created_at}</p>
                <a className='ride__phone-number' href={`tel:${ride.user.phone}`}>
                    {ride.user.phone}
                </a>
            </div>

            <div className='ride__content'>
                <div className='ride__info'>
                    <p className='text-bold'>Adresse de l'utilisateur</p>
                    <p>{ride.address}</p>
                </div>

                <div className='ride__info'>
                    <p className='text-bold'>Destination</p>
                    <p>{ride.destination}</p>
                </div>

                {stateMessage && <p className={`text-bold text-${stateMessage.state}`}>{stateMessage.message}</p>}
            </div>
        </div>
    );
};

export default RideCompleted;
