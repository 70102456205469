import React from 'react';
import { Outlet } from 'react-router-dom';

// Components
import Navigation from '../../components/navigation/Navigation';

const BasePage = () => {
    return (
        <>
            <Navigation />
            <main className='main'>
                <Outlet />
            </main>
        </>
    );
};

export default BasePage;
