import ApiConstants from '../ApiConstants';

const TAG = 'NotificationApi: ';

const notificationApi = {
    subscribe: (request) =>
        fetch(
            `${ApiConstants.NOTIFICATION_SUBSCRIBE_API_ROUTE}?token=${request.token}&webnotif_endpoint=${request.endpoint}&webnotif_publicKey=${request.publicKey}&webnotif_authToken=${request.authToken}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),
};

export default notificationApi;
