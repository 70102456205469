import { useEffect } from 'react';
import useSubscribeApi from '../apis/notification/hooks/useSubscribeApi';
import useAuthContext from '../context/authContext/useAuthContext';
const { REACT_APP_PUBLIC_KEY } = process.env;

const useNotification = () => {
    const { user } = useAuthContext();
    const { request: saveSubscription } = useSubscribeApi();

    const askPermission = async () => {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            registerServiceWorker();
        }
    };

    useEffect(() => {
        if (user) {
            askPermission();
        }
    }, [user]);

    const registerServiceWorker = async () => {
        const registration = await navigator.serviceWorker.register('./service-worker.js');
        let subscription = await registration.pushManager.getSubscription();

        const messageChannel = new MessageChannel();
        messageChannel.port1.addEventListener('message', (event) => console.log(event.data));

        if (!subscription) {
            subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: REACT_APP_PUBLIC_KEY,
            });
        }

        const { endpoint, keys } = subscription.toJSON();

        await saveSubscription({
            token: user.token,
            endpoint: endpoint,
            publicKey: keys.p256dh,
            authToken: keys.auth,
        });
    };
};

export default useNotification;
