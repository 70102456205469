import React from 'react';

export default function Notification({ bgOnClick, children, status }) {
    return (
        <div className='notification-wrapper'>
            <div className='container'>
                <div className='background' onClick={bgOnClick}></div>
                <div className={`grid notification ${status && status}`}>{children}</div>
            </div>
        </div>
    );
}
