import { useState, useCallback } from 'react';

//CONSTANTES
const FREQUENCE = 3000;

const arrondir = (number) => {
    return Math.round(number * 10000) / 10000;
};

const useFetchPosition = () => {
    const [lastLatitude, setLastLatitude] = useState();
    const [lastLongitude, setLastLongitude] = useState();
    const [partagerPosition] = useState(true);
    const [requestTimestamp, setRequestTimestamp] = useState(0);

    //surveille si la position change pour envoyer les données
    const sendPosition = useCallback(
        (location, fetchLocation) => {
            if (location && partagerPosition) {
                if (location.timestamp - requestTimestamp > FREQUENCE) {
                    if (
                        arrondir(location.latitude) !== arrondir(lastLatitude) ||
                        arrondir(location.longitude) !== arrondir(lastLongitude)
                    ) {
                        setRequestTimestamp(location.timestamp);
                        setLastLatitude(location.latitude);
                        setLastLongitude(location.longitude);
                        fetchLocation(location.latitude, location.longitude);
                    }
                }
            }
        },
        [lastLatitude, lastLongitude, partagerPosition, requestTimestamp]
    );

    return { sendPosition };
};

export default useFetchPosition;
