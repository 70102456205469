import React from 'react';
import { useFormContext } from 'react-hook-form';

function EmailInput({ name, label }) {
    const {
        register,
        formState: { errors },
    } = useFormContext();

    return (
        <div
            className={`form-group ${
                errors.email?.type === 'required' ? 'error' : ''
            }`}
        >
            <label htmlFor={name}>{label}</label>
            <input
                id={name}
                type='email'
                className='form-control'
                {...register(name, { required: true })}
            />
            {errors.email?.type === 'required' && (
                <p className='text-error'>Email requis</p>
            )}
        </div>
    );
}

export default EmailInput;
