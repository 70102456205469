import React from 'react';

function Button({ children, onClick, status, disabled }) {
    const handleOnClick = () => {
        if (disabled) {
            return;
        }
        onClick();
    };

    return (
        <button
            className={`button ${status}`}
            onClick={handleOnClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
}

export default Button;
