import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

//Logo;
import logo from '../../assets/logo.png';

//Components
import Loader from '../../components/loader/Loader';
import Btn from '../../components/button/Btn';

//Icons
import PasswordInput from '../../components/form/PasswordInput';
import EmailInput from '../../components/form/EmailInput';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

export default function Login() {
    const methods = useForm();
    const location = useLocation();
    const from = location.state?.from?.pathname || '/';
    const { login, error, loading } = useAuth(from);

    const onSubmit = async (formData) => {
        const { email, password } = formData;
        login({ email, password });
    };

    return (
        <div className='page-wrapper center'>
            <div className='container'>
                <div className='text-center mb-5 mx-2 px-5'>
                    <img src={logo} alt='Logo' />
                </div>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onSubmit)}>
                        <div className='grid'>
                            <EmailInput name='email' label='Adresse email' />
                            <PasswordInput name='password' label='Mot de passe' />
                            <Btn disabled={loading} type='submit'>
                                Connexion
                                {loading && <Loader />}
                            </Btn>
                            {error && <p className='text-center text-error'>{error}</p>}
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    );
}
