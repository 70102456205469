import { useCallback, useState } from 'react';

const useFetch = (fn) => {
    const [data, setData] = useState(null);
    const [error, setError] = useState('');
    const [loading, setloading] = useState(false);

    const request = useCallback(
        async (data) => {
            setError('');
            setloading(true);
            try {
                const response = await fn(data);
                if (!response) {
                    throw new Error('Aucune réponse du serveur');
                }
                if (response.error) {
                    return setError(response.message);
                }
                setData(response);
                return response;
            } catch (error) {
                console.error('ERROR', error);
                setError(error);
            } finally {
                setloading(false);
            }
        },
        [fn]
    );

    return { data, error, loading, request };
};

export default useFetch;
