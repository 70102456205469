import { Navigate, Outlet, useLocation } from 'react-router-dom';
import useAuthContext from '../context/authContext/useAuthContext';

const ProtectedRoute = ({ allowedRoles }) => {
    const { user } = useAuthContext();
    const location = useLocation();

    if (allowedRoles?.includes(user?.user_type)) {
        return <Outlet />;
    }

    if (user?.user_type) {
        return <Navigate to='/unauthorized' state={{ from: location }} replace />;
    }

    return <Navigate to='/login' state={{ from: location }} replace />;
};

export default ProtectedRoute;
