import React from 'react';

export default function Btn({ children, type, onClick, disabled }) {
    return (
        <div className='btn-container'>
            <button disabled={disabled} onClick={onClick} type={type} className='btn'>
                {children}
            </button>
        </div>
    );
}
