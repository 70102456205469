const BASE_URL = 'https://navette-biguglia.lagenza.fr';
const BASE_API_ROUTE = BASE_URL + '/application_navette/webservice';

const ApiConstants = {
    BASE_URL: BASE_URL,
    BASE_API_ROUTE: BASE_API_ROUTE,
    LOGIN_API_ROUTE: BASE_API_ROUTE + '/login.php',

    // Chauffeur
    CHAUFFEUR_AFFECTER_API_ROUTE: BASE_API_ROUTE + '/affectation.php',
    CHAUFFEUR_DISSOCIER_API_ROUTE: BASE_API_ROUTE + '/dissociation.php',
    CHAUFFEUR_POSITION_API_ROUTE: BASE_API_ROUTE + '/position.php',

    // Ride
    RIDE_GET_LISTE_API_ROUTE: BASE_API_ROUTE + '/course_recup_liste.php',
    RIDE_ACCEPTER_API_ROUTE: BASE_API_ROUTE + '/course_accepter.php',
    RIDE_REFUSER_API_ROUTE: BASE_API_ROUTE + '/course_refuser.php',
    RIDE_ANNULER_API_ROUTE: BASE_API_ROUTE + '/course_annuler.php',
    RIDE_TERMINER_API_ROUTE: BASE_API_ROUTE + '/course_terminer.php',

    // Notifications
    NOTIFICATION_SUBSCRIBE_API_ROUTE: BASE_API_ROUTE + '/souscrire_web.php',
};

export default ApiConstants;
