import React from 'react';

const Header = ({ title }) => {
    return (
        <div className='header'>
            <div className='container'>
                <p className='header__title'>{title}</p>
            </div>
        </div>
    );
};

export default Header;
