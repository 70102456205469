import ApiConstants from '../ApiConstants';

const TAG = 'AuthenticationApi: ';

const authenticationApi = {
    login: (data) =>
        fetch(
            `${ApiConstants.LOGIN_API_ROUTE}?username=${data.username}&password=${data.password}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),
};

export default authenticationApi;
