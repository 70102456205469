import * as React from 'react';

export default function BorderIcon({ children, size, color, onClick }) {
    return (
        <div className={`border-icon ${color}`} style={{ width: size, height: size }} onClick={onClick}>
            {children}
        </div>
    );
}

BorderIcon.defaultProps = {
    size: 40,
};
