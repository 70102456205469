import Header from '../../components/header/Header';

const Unauthorized = () => {
    return (
        <section className='container'>
            <Header title='Désolé' />
            <br />
            <br />
            <br />
            <p>Vous n'êtes pas autorisé à accéder à cette page.</p>
        </section>
    );
};

export default Unauthorized;
