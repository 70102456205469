import { useContext } from 'react';
import { RidesContext } from './RidesContext';

const useRidesContext = () => {
    const context = useContext(RidesContext);

    if (context === undefined) {
        throw new Error(
            'useRidesContext est utilisé en dehors de son provider'
        );
    }

    return context;
};

export default useRidesContext;
