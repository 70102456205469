import { createContext, useState } from 'react';

// create context
const RidesContext = createContext(null);

const RidesProvider = ({ children }) => {
    const [rides, setRides] = useState([]);

    return (
        <RidesContext.Provider value={{ rides, setRides }}>
            {children}
        </RidesContext.Provider>
    );
};

export { RidesContext, RidesProvider };
