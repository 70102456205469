import React, { useState } from 'react';
import Button from '../../../components/button/Button';
import ModalCancel from './ModalCancel';
import ModalFinish from './ModalFinish';

const RideAccepted = ({ ride }) => {
    const [isFinishModalOpen, setIsFinishModalOpen] = useState(false);
    const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);

    const openFinishModal = () => {
        setIsFinishModalOpen(true);
    };
    const openCancelModal = () => {
        setIsCancelModalOpen(true);
    };

    return (
        <div className='ride'>
            <div className='ride__header'>
                <p className='ride__title'>
                    {ride.user.nom} {ride.user.prenom}
                </p>
                <p>{ride.created_at}</p>
                <a className='ride__phone-number' href={`tel:${ride.user.phone}`}>
                    {ride.user.phone}
                </a>
            </div>

            <div className='ride__content'>
                <div className='ride__info'>
                    <p className='text-bold'>Adresse de l'utilisateur</p>
                    <p>{ride.address}</p>
                </div>

                <div className='ride__info'>
                    <p className='text-bold'>Destination</p>
                    <p>{ride.destination}</p>
                </div>

                <div className='ride__info'>
                    <p className='text-bold text-center ride__pickup-hour'>Ramassage prévu à {ride.pickup}</p>
                </div>

                <div className='ride__actions'>
                    <Button status='error' onClick={openCancelModal}>
                        Annuler
                    </Button>
                    <Button status='success' onClick={openFinishModal}>
                        Terminer
                    </Button>
                </div>
            </div>

            <ModalFinish
                isFinishModalOpen={isFinishModalOpen}
                setIsFinishModalOpen={setIsFinishModalOpen}
                rideId={ride.id}
            />

            <ModalCancel
                isCancelModalOpen={isCancelModalOpen}
                setIsCancelModalOpen={setIsCancelModalOpen}
                rideId={ride.id}
            />
        </div>
    );
};

export default RideAccepted;
