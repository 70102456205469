import ApiConstants from '../ApiConstants';

const TAG = 'RideApi: ';

const rideApi = {
    getListe: (data) =>
        fetch(`${ApiConstants.RIDE_GET_LISTE_API_ROUTE}?token=${data.token}`)
            .then((response) => {
                return response.json();
            })
            .catch((error) => {
                console.log(TAG, error);
            }),
    accepter: (data) =>
        fetch(
            `${ApiConstants.RIDE_ACCEPTER_API_ROUTE}?token=${data.token}&course_id=${data.id}&course_delai=${data.delay}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),

    refuser: (data) =>
        fetch(
            `${ApiConstants.RIDE_REFUSER_API_ROUTE}?token=${data.token}&course_id=${data.id}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),

    annuler: (data) =>
        fetch(
            `${ApiConstants.RIDE_ANNULER_API_ROUTE}?token=${data.token}&course_id=${data.id}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),

    terminer: (data) =>
        fetch(
            `${ApiConstants.RIDE_TERMINER_API_ROUTE}?token=${data.token}&course_id=${data.id}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),
};

export default rideApi;
