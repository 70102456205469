import * as React from 'react';

export default function WarningIcon() {
    return (
        <svg width='1em' height='1em' viewBox='0 0 208 182' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M206.996 170.857L110.43 3.714C108.991 1.23 106.507 0 104 0c-2.507 0-5.014 1.23-6.43 3.714L1.004 170.857c-2.855 4.968.72 11.143 6.43 11.143h193.132c5.71 0 9.285-6.175 6.43-11.143zM96.572 68.714c0-1.021.835-1.857 1.857-1.857h11.142c1.021 0 1.857.836 1.857 1.857v42.715a1.863 1.863 0 01-1.857 1.857H98.429a1.863 1.863 0 01-1.857-1.857V68.714zM104 150.43a11.147 11.147 0 01-7.733-18.943 11.142 11.142 0 017.733-3.343 11.143 11.143 0 0110.917 11.143A11.148 11.148 0 01104 150.429z'
                fill='currentColor'
            />
        </svg>
    );
}
