import { memo } from 'react';

import RideAccepted from './RideAccepted';

const ListRidesAccepted = memo(function ListRidesAccepted({ rides }) {
    if (!rides.length) {
        return null;
    }

    return (
        <div>
            <p className='text-bold text-uppercase'>Prises en charge acceptées</p>
            {rides.map((ride) => (
                <RideAccepted key={ride.id} ride={ride} />
            ))}
        </div>
    );
});

export default ListRidesAccepted;
