import { ToastContainer as ToastityContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMessage from '../components/toastMessage/ToastMessage';

const useToast = () => {
    const notify = (message, options = {}) => {
        const state = options.state ?? 'info';

        toast[state](<ToastMessage title={message.title} message={message.message} />, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            theme: 'colored',
            icon: false,
            ...options,
        });
    };

    const ToastContainer = () => {
        return <ToastityContainer />;
    };

    return { ToastContainer, notify };
};

export default useToast;
