import React, { useEffect } from 'react';

import Header from '../../components/header/Header';
import Submitting from '../../components/submitting/Submitting';

// api
import useGetAllRides from '../../apis/ride/hooks/useGetAllRides';

// context
import useRidesContext from '../../context/ridesContext/useRidesContext';
import useAuthContext from '../../context/authContext/useAuthContext';
import RidesList from './components/RidesList';

function Rides() {
    const { rides, setRides } = useRidesContext();

    const { user } = useAuthContext();
    const { request: getRides, loading } = useGetAllRides();

    const fetchRides = () => {
        getRides({ token: user.token }).then((response) => {
            if (response && response.data) {
                setRides(response.data);
            }
        });
    };

    useEffect(() => {
        fetchRides();

        navigator.serviceWorker.addEventListener('message', fetchRides);

        return () => {
            navigator.serviceWorker.removeEventListener('message', fetchRides);
        };
    }, []);

    if (loading) {
        return <Submitting />;
    }

    return (
        <div>
            <Header title='Courses' />
            <div className='container'>
                <RidesList rides={rides} />
            </div>
        </div>
    );
}

export default Rides;
