import React, { createContext, useContext, useState } from 'react';

// create context
const ChauffeurContext = createContext();

const ChauffeurContextProvider = ({ children }) => {
    const [isChauffeurAffecte, setIsChauffeurAffecte] = useState(false);

    return (
        <ChauffeurContext.Provider value={{ isChauffeurAffecte, setIsChauffeurAffecte }}>
            {children}
        </ChauffeurContext.Provider>
    );
};

const useChauffeurContext = () => {
    const context = useContext(ChauffeurContext);

    if (context === undefined) {
        throw new Error('useChauffeurContext est utilisé en dehors de son provider');
    }

    return context;
};

export { ChauffeurContextProvider, useChauffeurContext };
