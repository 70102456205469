import React, { useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import EyeCloseIcon from '../icons/EyeCloseIcon';
import EyeIcon from '../icons/EyeIcon';

function PasswordInput({ name, label }) {
    const {
        register,
        formState: { errors },
        getValues,
    } = useFormContext();

    const { ref } = register(name);

    const [passwordShown, setPasswordShown] = useState(false);
    const inputPassword = useRef(null);

    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
        const inputPasswordLength = getValues(name).length;
        inputPassword.current.focus();
        setTimeout(() => {
            inputPassword.current.setSelectionRange(
                inputPasswordLength,
                inputPasswordLength
            );
        }, 0);
    };

    return (
        <div
            className={`form-group mb-2 ${
                errors[name]?.type === 'required' ? 'error' : ''
            }`}
        >
            <label htmlFor={name}>{label}</label>
            <div className='input-icon'>
                <input
                    id={name}
                    type={passwordShown ? 'text' : 'password'}
                    className='form-control'
                    {...register(name, {
                        required: true,
                    })}
                    ref={(e) => {
                        ref(e);
                        inputPassword.current = e; // you can still assign to ref
                    }}
                />
                <i onClick={togglePasswordVisiblity} className='icon'>
                    {passwordShown ? <EyeIcon /> : <EyeCloseIcon />}
                </i>
            </div>
            {errors[name]?.type === 'required' && (
                <p className='text-error'>{label} requis</p>
            )}
        </div>
    );
}

export default PasswordInput;
