import React, { useEffect } from 'react';

// pages
import { AppRoutes } from '../routes/AppRoutes';

// context
import { useNavigate } from 'react-router-dom';
import { ChauffeurContextProvider } from '../context/chauffeurContext/ChauffeurContext';
import useNotification from '../hooks/useNotification';
import useToast from '../hooks/useToast';

const AppContainer = () => {
    useNotification();
    const { notify } = useToast();

    const navigate = useNavigate();

    useEffect(() => {
        const handleNotification = (e) => {
            notify(e.data, {
                state: e.data.state,
                onClick: () => navigate('/rides'),
            });
        };
        navigator.serviceWorker.addEventListener('message', handleNotification);

        return () => {
            navigator.serviceWorker.removeEventListener('message', handleNotification);
        };
    }, []);

    return (
        <ChauffeurContextProvider>
            <AppRoutes />
        </ChauffeurContextProvider>
    );
};

export default AppContainer;
