import React, { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

// pages
import BasePage from '../pages/_layouts/BaseLayout';
import LoginPage from '../pages/login/Login';

import Home from '../pages/home/Home';
import Rides from '../pages/rides/Rides';
import ProtectedRoute from './ProtectedRoute';
import Unauthorized from '../pages/unauthorized/Unauthorized';

const ROLES = {
    User: 'client',
    Driver: 'chauffeur',
};

export const AppRoutes = () => {
    // Scroll to top on page change
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <Routes>
            <Route path='/' element={<BasePage />}>
                <Route path='unauthorized' element={<Unauthorized />} />

                <Route element={<ProtectedRoute allowedRoles={[ROLES.Driver]} />}>
                    <Route path='/' element={<Home />} />
                    <Route path='rides' element={<Rides />} />
                </Route>
                <Route path='rides-auth' element={<Rides />} />
            </Route>
            <Route path='/login' element={<LoginPage />} />
        </Routes>
    );
};
