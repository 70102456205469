import * as React from 'react';

function HomeIcon() {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 21 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M19.743 10.316l-9-10c-.379-.421-1.107-.421-1.486 0l-9 10a.998.998 0 00-.17 1.076c.16.362.518.594.913.594h2v7a1 1 0 001 1h3a1 1 0 001-1v-4h4v4a1 1 0 001 1h3a1 1 0 001-1v-7h2a.997.997 0 00.743-1.67z'
                fill='currentColor'
            />
        </svg>
    );
}

export default HomeIcon;
