import React from 'react';
import useGetAllRides from '../../../apis/ride/hooks/useGetAllRides';
import useRefuserRideApi from '../../../apis/ride/hooks/useRefuserRideApi';
import Btn from '../../../components/button/Btn';
import Button from '../../../components/button/Button';
import Notification from '../../../components/notification/Notification';
import useAuthContext from '../../../context/authContext/useAuthContext';
import useRidesContext from '../../../context/ridesContext/useRidesContext';

function ModalDeny({ isDenyModalOpen, setIsDenyModalOpen, rideId }) {
    const { user } = useAuthContext();
    const { setRides } = useRidesContext();
    const { request: getRides } = useGetAllRides();
    const { request: refuser } = useRefuserRideApi();

    const closeNotification = () => {
        setIsDenyModalOpen(false);
    };

    const handleDeny = () => {
        const request = {
            token: user.token,
            id: rideId,
        };
        refuser(request)
            .then(() => {
                getRides({ token: user.token }).then((response) => {
                    if (response && response.data) {
                        setRides(response.data);
                    }
                });
                setIsDenyModalOpen(false);
            })
            .catch((err) => console.error(err));
    };

    if (!isDenyModalOpen) {
        return null;
    }

    return (
        <Notification bgOnClick={closeNotification}>
            <p>Souhaitez-vous vraiment refuser ?</p>
            <Button status='error' onClick={handleDeny}>
                Refuser
            </Button>
            <Btn onClick={closeNotification}>Fermer</Btn>
        </Notification>
    );
}

export default ModalDeny;
