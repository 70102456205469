import { useEffect } from 'react';
import { useWakeLock } from 'react-screen-wake-lock';

const useScreenLock = () => {
    const { isSupported, request } = useWakeLock({
        onRequest: () => console.log('Screen Wake Lock: requested!'),
        onError: () => alert("Erreur dans l'accès au vérouillage de l'écran 💥"),
        onRelease: () => console.log('Screen Wake Lock: released!'),
    });

    useEffect(() => {
        if (isSupported) {
            request();
        }
    }, [isSupported]);
};

export default useScreenLock;
