import * as React from 'react';

function AskRideIcon() {
    return (
        <svg
            width='1em'
            height='1em'
            viewBox='0 0 16 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path d='M7.92 4a2 2 0 100-4 2 2 0 000 4z' fill='currentColor' />
            <path
                d='M11.81 6.11C11.42 5.72 10.75 5 9.45 5H6.91A5.023 5.023 0 011.99.85.998.998 0 001.01 0C.4 0-.08.54.01 1.14a7.037 7.037 0 004.91 5.57V19c0 .55.45 1 1 1s1-.45 1-1v-5h2v5c0 .55.45 1 1 1s1-.45 1-1V8.05l3.24 3.24a.997.997 0 001.41-1.41l-3.76-3.77z'
                fill='currentColor'
            />
        </svg>
    );
}

export default AskRideIcon;
