import { memo } from 'react';

import RidePending from './RidePending';

const ListRidesPending = memo(function ListRidesPending({ rides }) {
    if (!rides.length) {
        return null;
    }

    return (
        <div>
            <p className='text-bold text-uppercase'>
                Nouvelles prises en charge
            </p>
            {rides.map((ride) => (
                <RidePending key={ride.id} ride={ride} />
            ))}
        </div>
    );
});

export default ListRidesPending;
