import React, { useEffect, useState } from 'react';

//Hooks
import useWatchLocation from '../../hooks/useWatchLocation';

//Components
import Button from '../../components/button/Button';
import Notification from '../../components/notification/Notification';
import Scanner from '../../components/scanner/Scanner';

//Icons
import useAffecterChauffeurApi from '../../apis/chauffeur/hooks/useAffecterChauffeurApi';
import useDissocierChauffeurApi from '../../apis/chauffeur/hooks/useDissocierChauffeurApi';
import useEnvoyerPositionChauffeurApi from '../../apis/chauffeur/hooks/useEnvoyerPositionChauffeurApi';
import Btn from '../../components/button/Btn';
import BorderIcon from '../../components/icons/BorderIcon';
import CheckIcon from '../../components/icons/CheckIcon';
import ScannerWithSale from '../../components/scanner/ScannerWithSale';
import VersionNumber from '../../components/versionNumber/VersionNumber';
import useAuthContext from '../../context/authContext/useAuthContext';
import { useChauffeurContext } from '../../context/chauffeurContext/ChauffeurContext';
import NotificationError from './components/NotificationError';
import useFetchPosition from './hooks/useFetchPosition';
import useScreenLock from './hooks/useScreenLock';

const { REACT_APP_BUS_CODE, REACT_APP_LINE_CODE } = process.env;

function Home() {
    useScreenLock();
    const { sendPosition } = useFetchPosition();
    const { user } = useAuthContext();
    const {
        request: affecterChauffeur,
        error: errorAffecter,
        loading: loadingAssocier,
    } = useAffecterChauffeurApi();
    const {
        request: dissocierChauffeur,
        error: errorDissocier,
        loading: loadingDissocier,
    } = useDissocierChauffeurApi();
    const { request: envoyerPositionChauffeur } = useEnvoyerPositionChauffeurApi();

    const [isScannerOpen, setIsScannerOpen] = useState(false);
    const [isScannerWithSaleOpen, setIsScannerWithSaleOpen] = useState(false);

    const { location, allowLocationWatch } = useWatchLocation({ enableHighAccuracy: true });

    const { isChauffeurAffecte, setIsChauffeurAffecte } = useChauffeurContext();
    const [showNotification, setShowNotification] = useState(false);
    const [notificationStatus] = useState();
    const [notificationMessage] = useState('');

    useEffect(() => {
        allowLocationWatch();
    }, []);

    const handleStart = () => {
        const { token } = user;
        const request = {
            token,
            busCode: REACT_APP_BUS_CODE,
            lineCode: REACT_APP_LINE_CODE,
        };

        affecterChauffeur(request)
            .then((response) => {
                if (response) {
                    setIsChauffeurAffecte(true);
                }
            })
            .catch((err) => console.error(err));
    };

    const handleStop = () => {
        const { token } = user;
        const request = {
            token,
            busCode: REACT_APP_BUS_CODE,
            lineCode: REACT_APP_LINE_CODE,
        };

        dissocierChauffeur(request)
            .then((response) => {
                if (response) {
                    setIsChauffeurAffecte(false);
                }
            })
            .catch((err) => console.error(err));
    };

    const handleFetchPosition = (latitude, longitude) => {
        const { token } = user;
        const request = {
            token,
            busCode: REACT_APP_BUS_CODE,
            latitude,
            longitude,
        };

        envoyerPositionChauffeur(request)
            .then((response) => {
                if (response.error === false) {
                    console.log('RESPONSE', response);
                } else {
                    alert(response);
                }
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        if (isChauffeurAffecte) {
            sendPosition(location, handleFetchPosition);
        }
    }, [location]);

    const handleOpenScanner = () => {
        setIsScannerOpen(true);
    };

    const handleOpenScannerWithSale = () => {
        setIsScannerWithSaleOpen(true);
    };

    return (
        <>
            <div className='section bg-primary'>
                {isChauffeurAffecte ? (
                    <Button onClick={handleStop} status='error' disabled={loadingDissocier}>
                        Arrêter
                    </Button>
                ) : (
                    <Button onClick={handleStart} disabled={loadingAssocier}>
                        Démarrer
                    </Button>
                )}
            </div>
            <div className='section'>
                <Button onClick={handleOpenScanner}>Scanner</Button>
                <Button onClick={handleOpenScannerWithSale}>Scanner avec vente</Button>
            </div>
            <VersionNumber />

            {isScannerOpen && <Scanner setOpenScanner={setIsScannerOpen} />}
            {isScannerWithSaleOpen && <ScannerWithSale setOpenScanner={setIsScannerWithSaleOpen} />}

            {showNotification && notificationStatus === 'success' && (
                <Notification status={'success'} bgOnClick={() => setShowNotification(false)}>
                    <BorderIcon color='success'>
                        <CheckIcon />
                    </BorderIcon>
                    {notificationMessage && <p>{notificationMessage}</p>}
                    <Btn onClick={() => setShowNotification(false)}>Fermer</Btn>
                </Notification>
            )}

            {errorAffecter && <NotificationError error={errorAffecter} />}
            {errorDissocier && <NotificationError error={errorDissocier} />}
        </>
    );
}

export default Home;
