import React from 'react';
import useAccepterRideApi from '../../../apis/ride/hooks/useAccepterRideApi';
import Btn from '../../../components/button/Btn';
import Button from '../../../components/button/Button';
import Notification from '../../../components/notification/Notification';
import useAuthContext from '../../../context/authContext/useAuthContext';
import useRidesContext from '../../../context/ridesContext/useRidesContext';
import useGetAllRides from '../../../apis/ride/hooks/useGetAllRides';

const INTERVALLES = [5, 15, 30, 45];

function ModalAccept({ isAcceptModalOpen, setIsAcceptModalOpen, rideId }) {
    const { user } = useAuthContext();
    const { setRides } = useRidesContext();
    const { request: getRides } = useGetAllRides();

    const { request: accepter, loading: loadingAccepter } = useAccepterRideApi();

    const handleAccept = (delay) => {
        const request = {
            token: user.token,
            id: rideId,
            delay,
        };
        accepter(request)
            .then(() => {
                getRides({ token: user.token }).then((response) => {
                    if (response && response.data) {
                        setRides(response.data);
                    }
                });
                setIsAcceptModalOpen(false);
            })
            .catch((err) => console.error(err));
    };

    const closeNotification = () => {
        setIsAcceptModalOpen(false);
    };

    if (!isAcceptModalOpen) {
        return null;
    }

    return (
        <Notification bgOnClick={closeNotification}>
            <p>Dans combien de temps allez-vous récupérer la personne ?</p>
            {INTERVALLES.map((intervalle, index) => (
                <Button key={index} onClick={() => handleAccept(intervalle)} disabled={loadingAccepter}>
                    {intervalle} min
                </Button>
            ))}
            <Btn onClick={closeNotification} disabled={loadingAccepter}>
                Fermer
            </Btn>
        </Notification>
    );
}

export default ModalAccept;
