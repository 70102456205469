import React, { useState } from 'react';
import Button from '../../../components/button/Button';
import ModalAccept from './ModalAccept';
import ModalDeny from './ModalDeny';

const RidePending = ({ ride }) => {
    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);
    const [isDenyModalOpen, setIsDenyModalOpen] = useState(false);

    const openAcceptModal = () => {
        setIsAcceptModalOpen(true);
    };
    const openDenyModal = () => {
        setIsDenyModalOpen(true);
    };

    return (
        <div className='ride'>
            <div className='ride__header'>
                <p className='ride__title'>
                    {ride.user.nom} {ride.user.prenom}
                </p>
                <p>{ride.created_at}</p>
                <a className='ride__phone-number' href={`tel:${ride.user.phone}`}>
                    {ride.user.phone}
                </a>
            </div>

            <div className='ride__content'>
                <div className='ride__info'>
                    <p className='text-bold'>Adresse de l'utilisateur</p>
                    <p>{ride.address}</p>
                </div>

                <div className='ride__info'>
                    <p className='text-bold'>Destination</p>
                    <p>{ride.destination}</p>
                </div>

                <div className='ride__actions'>
                    <Button status='error' onClick={openDenyModal}>
                        Refuser
                    </Button>
                    <Button onClick={openAcceptModal}>Accepter</Button>
                </div>
            </div>

            <ModalAccept
                isAcceptModalOpen={isAcceptModalOpen}
                setIsAcceptModalOpen={setIsAcceptModalOpen}
                rideId={ride.id}
            />

            <ModalDeny isDenyModalOpen={isDenyModalOpen} setIsDenyModalOpen={setIsDenyModalOpen} rideId={ride.id} />
        </div>
    );
};

export default RidePending;
