import React, { useMemo } from 'react';

import ListRidesAccepted from './ListRidesAccepted';
import ListRidesPending from './ListRidesPending';
import ListRidesCompleted from './ListRidesCompleted';

const filterRides = (rides, state = []) => {
    if (!rides) {
        return [];
    }
    return rides.filter((ride) => state.includes(ride.state));
};

function RidesList({ rides }) {
    const ridesPending = useMemo(() => {
        return filterRides(rides, ['En attente']);
    }, [rides]);
    const ridesAccepted = useMemo(() => {
        return filterRides(rides, ['Acceptée', '']);
    }, [rides]);
    const ridesCompleted = useMemo(() => {
        return filterRides(rides, ['Terminée', 'Annulée', 'Déclinée', 'Refusée']);
    }, [rides]);

    return (
        <div className='rides'>
            {rides.length === 0 ? (
                <p>Aucune course pour le moment</p>
            ) : (
                <>
                    <ListRidesPending rides={ridesPending} />
                    <ListRidesAccepted rides={ridesAccepted} />
                    <ListRidesCompleted rides={ridesCompleted} />
                </>
            )}
        </div>
    );
}

export default RidesList;
