import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-reader';

//Components
import Notification from '../notification/Notification';
import Btn from '../button/Btn';

//Icons
import BorderIcon from '../icons/BorderIcon';
import WarningIcon from '../icons/WarningIcon';
import CheckIcon from '../icons/CheckIcon';
import Submitting from '../submitting/Submitting';

function ScannerWithSale({ setOpenScanner }) {
    const [scanReader, setScanReader] = useState(true);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationStatus, setNotificationStatus] = useState();
    const [notificationMessage, setNotificationMessage] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleScan = (token) => {
        if (token) {
            setIsSubmitting(true);
            setScanReader(false);

            fetch(`https://navette-biguglia.lagenza.fr/application_navette/webservice/scan.php?token=${token}&vente=1`)
                .then((res) => res.json())
                .then(
                    (data) => {
                        if (data.error === false) {
                            setNotificationMessage(data.message);
                            setNotificationStatus('success');
                            setShowNotification(true);
                        } else {
                            setNotificationStatus('error');
                            setNotificationMessage(data.message);
                            setShowNotification(true);
                        }
                        setIsSubmitting(false);
                    },
                    (error) => {
                        setIsSubmitting(false);
                        alert(error);
                    }
                );
        }
    };

    const closeScanner = () => {
        setShowNotification(false);
        setScanReader(false);
        setOpenScanner(false);
    };

    return (
        <div className='scanner-wrapper'>
            {scanReader && (
                <>
                    <div className='overlay' onClick={() => setOpenScanner(false)}></div>
                    <QrReader className='scanner' delay={500} onError={(error) => alert(error)} onScan={handleScan} />
                </>
            )}

            {showNotification && notificationStatus === 'success' && (
                <Notification status={'success'} bgOnClick={closeScanner}>
                    <BorderIcon color='success'>
                        <CheckIcon />
                    </BorderIcon>
                    {notificationMessage && <p>{notificationMessage}</p>}
                    <Btn onClick={closeScanner}>Fermer</Btn>
                </Notification>
            )}

            {showNotification && notificationStatus === 'error' && (
                <Notification status={'error'} bgOnClick={closeScanner}>
                    <BorderIcon color='error'>
                        <WarningIcon />
                    </BorderIcon>
                    {notificationMessage && <p>{notificationMessage}</p>}
                    <Btn onClick={closeScanner}>Fermer</Btn>
                </Notification>
            )}

            {isSubmitting && <Submitting />}
        </div>
    );
}

export default ScannerWithSale;
