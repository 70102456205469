import React from 'react';
import './styles/main.scss';
import { BrowserRouter } from 'react-router-dom';

import AppContainer from './pages/AppContainer';

// Contexts
import { AuthProvider } from './context/authContext/AuthContext';
import { RidesProvider } from './context/ridesContext/RidesContext';
import useToast from './hooks/useToast';

function App() {
    const { ToastContainer } = useToast();
    return (
        <BrowserRouter>
            <AuthProvider>
                <RidesProvider>
                    <AppContainer />
                </RidesProvider>
            </AuthProvider>
            <ToastContainer />
        </BrowserRouter>
    );
}

export default App;
