import ApiConstants from '../ApiConstants';

const TAG = 'ChauffeurApi: ';

const chauffeurApi = {
    affecter: (data) =>
        fetch(
            `${ApiConstants.CHAUFFEUR_AFFECTER_API_ROUTE}?token=${data.token}&bus_code=${data.busCode}&line_code=${data.lineCode}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),

    dissocier: (data) =>
        fetch(
            `${ApiConstants.CHAUFFEUR_DISSOCIER_API_ROUTE}?token=${data.token}&bus_code=${data.busCode}&line_code=${data.lineCode}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),

    envoyerPosition: (data) =>
        fetch(
            `${ApiConstants.CHAUFFEUR_POSITION_API_ROUTE}?token=${data.token}&bus_code=${data.busCode}&longitude=${data.longitude}&latitude=${data.latitude}`,
            { method: 'POST' }
        )
            .then((response) => response.json())
            .catch((error) => {
                console.log(TAG, error);
            }),
};

export default chauffeurApi;
