import React from 'react';
import useGetAllRides from '../../../apis/ride/hooks/useGetAllRides';
import useTerminerRideApi from '../../../apis/ride/hooks/useTerminerRideApi';
import Btn from '../../../components/button/Btn';
import Button from '../../../components/button/Button';
import Notification from '../../../components/notification/Notification';
import useAuthContext from '../../../context/authContext/useAuthContext';
import useRidesContext from '../../../context/ridesContext/useRidesContext';

function ModalFinish({ isFinishModalOpen, setIsFinishModalOpen, rideId }) {
    const { user } = useAuthContext();
    const { setRides } = useRidesContext();
    const { request: getRides } = useGetAllRides();
    const { request: terminer } = useTerminerRideApi();

    const closeNotification = () => {
        setIsFinishModalOpen(false);
    };

    const handleFinish = () => {
        const request = {
            token: user.token,
            id: rideId,
        };
        terminer(request)
            .then(() => {
                getRides({ token: user.token }).then((response) => {
                    if (response && response.data) {
                        setRides(response.data);
                    }
                });
                setIsFinishModalOpen(false);
            })
            .catch((err) => console.error(err));
    };

    if (!isFinishModalOpen) {
        return null;
    }

    return (
        <Notification bgOnClick={closeNotification}>
            <p>Souhaitez-vous vraiment terminer la course ?</p>
            <Button status='success' onClick={handleFinish}>
                Terminer
            </Button>
            <Btn onClick={closeNotification}>Fermer</Btn>
        </Notification>
    );
}

export default ModalFinish;
