import React from 'react';

const ToastMessage = ({ title, message }) => {
    return (
        <div>
            <p className='text-bold'>{title}</p>
            <p>{message}</p>
        </div>
    );
};

export default ToastMessage;
