import React from 'react';
import { NavLink } from 'react-router-dom';
import AskRideIcon from '../../assets/icons/AskRideIcon';
import HomeIcon from '../../assets/icons/HomeIcon';
import LogOutIcon from '../../assets/icons/LogOutIcon';
import { useAuth } from '../../hooks/useAuth';

// icons
// import ActivityIcon from 'assets/icons/ActivityIcon';
// import MessageIcon from 'assets/icons/MessageIcon';
// import DocumentsIcon from 'assets/icons/DocumentsIcon';

const Navigation = () => {
    const { logout } = useAuth();

    const handleLogout = () => {
        logout();
    };

    return (
        <nav className='nav'>
            <ul>
                <li>
                    <NavLink to='/' className='nav__link'>
                        <HomeIcon />
                        Accueil
                    </NavLink>
                </li>
                <li>
                    <NavLink to='/rides' className='nav__link'>
                        <AskRideIcon />
                        Courses
                    </NavLink>
                </li>
                <li>
                    <button onClick={handleLogout} className='nav__link'>
                        <LogOutIcon />
                        Déconnexion
                    </button>
                </li>
            </ul>
        </nav>
    );
};

export default Navigation;
